import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 17 17"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "transparent",
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "1.5",
      d: "M11.264 6.176c2.4.206 3.38 1.44 3.38 4.14v.086c0 2.98-1.193 4.174-4.173 4.174H6.13c-2.98 0-4.173-1.194-4.173-4.174v-.086c0-2.68.966-3.914 3.326-4.134m3.021-4.608v8.587"
    }, null, -1),
    _createElementVNode("path", {
      fill: "transparent",
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "1.5",
      d: "m10.537 8.676-2.233 2.233-2.233-2.233"
    }, null, -1)
  ])))
}
export default { render: render }